// extracted by mini-css-extract-plugin
export var buttonContainer = "HeroHeader-module--buttonContainer--e09e7";
export var buttonWrapper = "HeroHeader-module--buttonWrapper--f9e60";
export var clinical = "HeroHeader-module--clinical--ef392";
export var column = "HeroHeader-module--column--e9bc1";
export var companyContainer = "HeroHeader-module--companyContainer--2fd77";
export var header = "HeroHeader-module--header--12a2f";
export var heartBanner = "HeroHeader-module--heartBanner--3a290";
export var heroHeader = "HeroHeader-module--heroHeader--cabf4";
export var heroHeaderContainer = "HeroHeader-module--heroHeaderContainer--839ae";
export var heroHeaderImage = "HeroHeader-module--heroHeaderImage--61721";
export var hundredTop = "HeroHeader-module--hundredTop--765c0";
export var logoContainer = "HeroHeader-module--logoContainer--d5dae";
export var marginImp = "HeroHeader-module--marginImp--97540";
export var pincAiLogoContainer = "HeroHeader-module--pincAiLogoContainer--a28c0";
export var secondButton = "HeroHeader-module--secondButton--a1cd2";
export var sloganContainer = "HeroHeader-module--sloganContainer--a237f";
export var subheaderContainer = "HeroHeader-module--subheaderContainer--5fd30";
export var textContainer = "HeroHeader-module--textContainer--08aa1";
export var titleContainer = "HeroHeader-module--titleContainer--202f7";
export var value = "HeroHeader-module--value--29dde";