import { PortableText } from '@portabletext/react'
import React from 'react'
import ButtonTemplate from '../../AppliedSciences/ASComponents/buttonTemplate/buttonTemplate'
import PincAiLogoAndText from '../../svg/PincAiLogoAndText'
import EbookArrow from '../../svg/EbookArrow'
import * as styles from './HeroHeader.module.scss'
import HeaderTicker from '../HeaderTicker/HeaderTicker'


const HeroHeader = (props) => {
  const { data, themeContext = null, ticker, route
    // pageContext
   } = props
  // const isHundredTopHome = pageContext?.hundredTopSlug.current === 'home'
  // const isHundredTopWinners = pageContext?.hundredTopSlug.current === 'winners'
  // console.log(ticker);

  let themeClass = styles.clinical;
    switch(themeContext) {
      case 'clinical-intelligence':
          themeClass = styles.clinical;
          break;
      case 'value-based-care':
          themeClass = styles.value;
          break;
      case 'margin-improvement':
          themeClass = styles.marginImp;
          break;
      case 'hundredTop':
          themeClass = styles.hundredTop;
          break;
      default:
          themeClass = null;
          break;
    }

  const customComponents = {
    marks: {
      footnote: ({ children }) => (
        <span>
          <sup>{children}</sup>
        </span>
      ),
    },
  }

  return (
    <section className={styles.heroHeaderContainer}>
      {ticker?._rawAnnouncements?.length > 0 &&
        <HeaderTicker data={ticker}/>
      }
      <div className={styles.heroHeader + " " + themeClass}>
      <div className={styles.heroHeaderImage}>
        <img src={data?.backgroundImage?.asset.url} alt="Header Block" />
        {/* <div className={styles.mask}></div> */}
      </div>
      <header className={styles.textContainer}>
        {/* <h2 className={styles.header}>{data?.companyName}</h2> */}
        {/* can add this logo to sanity */}
        {(themeContext === 'hundredTop' || !themeClass) &&
          <div className={styles.pincAiLogoContainer}>
            <PincAiLogoAndText width="130" textColor="#000" />
          </div>
        }
        {data?.companyName && themeContext !== 'hundredTop' ?
          <div className={styles.companyContainer}>
            <p>{data.companyName}</p>
          </div>
          : data?.slogan && 
          <div className={styles.sloganContainer}>
            <p>{data.slogan}</p>
          </div>
        }
        <div className={styles.titleContainer}>
          <PortableText value={data?._rawTitle} components={customComponents} />
        </div>
        <div className={styles.subheaderContainer}>
          <PortableText value={data?._rawSubheader} />
        </div>
        <div className={styles.buttonContainer + (route && route.includes('15-top-health-systems/winners') ? " " + styles.column : '')} >
          {data?.buttonOptions.map((button, index) => (
            <div className={`${styles.buttonWrapper} ${index === 1 ? styles.secondButton : ''}`} key={index}>
              <ButtonTemplate data={button} key={index}>
                {button?.buttonText}
                {index === 1 && <EbookArrow />}
              </ButtonTemplate>
            </div>
          ))}
        </div>
      </header>
      {((themeContext === 'hundredTop' || !themeClass) && data?.topLogo?.asset.url) &&
        <div className={styles.logoContainer}>
          <img src={data?.topLogo?.asset.url} alt="100 Top Logo" />
        </div>
      }
      {/* removing - was pushed to production too early */}
      {/* {pageContext?.hundredTopSlug?.current === '50-top-cardiovascular-hospitals' &&
        <div className={styles.heartBanner}>
          <HeartMonthBanner/>
        </div>
      } */}
      </div>
    </section>
  )
}

export default HeroHeader
